<template>
  <b-modal
    id="addadd-new-plant-sidebar"
    :visible="isAddNewPlantSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    right
    ok-only
    centered
    :title="$t('updatePlant')"
    sidebar-class="sidebar-lg"
    size="lg"
    :ok-title="$t('ok')"
    @hidden="formValidation(resetblankplant).resetForm"
    @change="(val) => changed(val)"
    @ok="onSubmit(blankPlantData)"
  >
    <b-media no-body class="p-2">
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            src="
                https://api.optitmr.com/Plant_Pictures/empty.jpg
              "
            height="100"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t("upload") }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!--/ reset -->
        <b-card-text></b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankplant
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-form-group :label="$t('sourcePlant')" label-for="type">
          <v-select
            v-model="sourceId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="plantList"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-form-group>
        <!-- Kod -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="kod"
        >
          <b-form-group :label="$t('plantName')" label-for="plantname">
            <b-form-input
              id="addplantname"
              v-model="blankPlantData.plantname"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- responsible -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="responsible"
        >
          <b-form-group :label="$t('resonsible')" label-for="responsible">
            <b-form-input
              id="addresponsible"
              v-model="blankPlantData.responsible"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- responsiblemobile -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="responsiblemobile"
        >
          <b-form-group
            :label="$t('resonsibleMobile')"
            label-for="responsiblemobile"
          >
            <b-form-input
              id="addresponsiblemobile"
              v-model="blankPlantData.responsiblemobile"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- email -->
        <validation-provider
          #default="validationContext"
          rules="required|email"
          name="email"
        >
          <b-form-group :label="$t('email')" label-for="email">
            <b-form-input
              id="addemail"
              v-model="blankPlantData.email"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- city -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="city"
        >
          <b-form-group :label="$t('city')" label-for="city">
            <b-form-input
              id="addcity"
              v-model="blankPlantData.city"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- address -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="address"
        >
          <b-form-group :label="$t('address')" label-for="address">
            <b-form-textarea
              id="addaddress"
              v-model="blankPlantData.address"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- numberofdairy -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="numberofdairy"
        >
          <b-form-group :label="$t('dairyCowCount')" label-for="numberofdairy">
            <b-form-input
              id="addnumberofdairy"
              v-model="blankPlantData.numberofdairy"
              autofocus
              type="number"
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- numberofbeef -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="numberofbeef"
        >
          <b-form-group :label="$t('beefCowCount')" label-for="numberofbeef">
            <b-form-input
              id="addnumberofbeef"
              v-model="blankPlantData.numberofbeef"
              autofocus
              type="number"
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- milkamount -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="milkamount"
        >
          <b-form-group :label="$t('milkAmount')" label-for="milkamount">
            <b-form-input
              id="addmilkamount"
              v-model="blankPlantData.milkamount"
              autofocus
              type="number"
              step="any"
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- milkavarage -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="milkavarage"
        >
          <b-form-group :label="$t('milkAvarage')" label-for="milkavarage">
            <b-form-input
              id="addmilkavarage"
              v-model="blankPlantData.milkavarage"
              autofocus
              type="number"
              step="any"
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- loclat -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="loclat"
        >
          <b-form-group :label="'Loc Lat'" label-for="loclat">
            <b-form-input
              id="addloclat"
              v-model="blankPlantData.loclat"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- loclong -->

        <validation-provider
          #default="validationContext"
          rules="required"
          name="loclong"
        >
          <b-form-group label="Loc Long" label-for="loclong">
            <b-form-input
              id="addloclong"
              v-model="blankPlantData.loclong"
              autofocus
              :state="
                formValidation(resetblankplant).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Form Actions -->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCardText,
  BFormFile,
  BMedia,
  BMediaBody,
  BMediaAside,
  BImg,
  BLink,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import usePlantList from "./usePlantList";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCardText,
    BFormFile,
    BMedia,
    BMediaBody,
    BImg,
    BMediaAside,
    BLink,
    BFormTextarea,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPlantSidebarActive",
    event: "update:is-add-new-plant-sidebar-active",
  },
  props: {
    isAddNewPlantSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      plantList: [],
      blankPlantData: {
        id: "",
        plantname: "",
        responsible: "",
        city: "",
        responsiblemobile: "",
        pictureurl: "",
        loclat: "",
        loclong: "",
        address: "",
        email: "",
        numberofdairy: 0,
        numberofbeef: 0,
        milkamount: 0,
        milkavarage: 0,
      },
      sourceId: 0,
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  async mounted() {
    var response = await this.$store.dispatch("plantsModule/fetchPlants");
    this.plantList = response.map((x) => {
      return {
        label: x.plantname,
        value: x.id,
      };
    });
    this.sourceId = this.plantList[0].value;
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.blankPlantData = {
        id: "",
        plantname: "",
        responsible: "",
        city: "",
        responsiblemobile: "",
        pictureurl: "",
        loclat: "",
        loclong: "",
        address: "",
        email: "",
        numberofdairy: 0,
        numberofbeef: 0,
        milkamount: 0,
        milkavarage: 0,
      };
      formValidation(this.resetblankplant).resetForm;
      this.$emit("update:is-add-new-plant-sidebar-active", val);
    },
    onSubmit(blankPlantData) {
      blankPlantData.imgstr = this.imgStr;
      var plant = blankPlantData;
      plant.Responsible = plant.responsible;
      store
        .dispatch("plantsModule/addPlant", {
          plant: plant,
          sourceId: this.sourceId,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-plant-sidebar-active", false);
        });
    },
    async resetblankplant() {
      this.blankPlantData = {
        id: "",
        plantname: "",
        responsible: "",
        city: "",
        responsiblemobile: "",
        pictureurl: "",
        loclat: "",
        loclong: "",
        address: "",
        email: "",
        numberofdairy: 0,
        numberofbeef: 0,
        milkamount: 0,
        milkavarage: 0,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-plant-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
